<template>
  <auth-content>
    <el-header slot="header" :title="$t('modules.actionsboard.page_titles.fasts')" />

    <div class="">
      <FastActions />
    </div>

  </auth-content>
</template>

<script>

import component from './component'

/**
 * @group _ Module Actionsboard
 * This component display fasts actions page
 */
export default {
  name: 'FastsIndex',
  components: {
    FastActions: component.FastActions,
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
</style>
