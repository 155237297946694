<template>
  <div class="h-100">
    <div
      id="todoDetailsWrapper"
      :class="{
        'position-top col-md-4 col-xl-3 col-xxl-2 pl-0 pl-md-3': false,
      }
    ">
      <div class="card shadow" v-if="data">
        <div class="card-header bg-light px-2 py-1">
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <label class="title-icon cursor-pointer m-2 float-left">
                <input class="d-none" type="checkbox" :checked="TaskToDoUpdate.done" @change="checkTask($event)">
                <i class="icon-checkbox-unchecked2" v-if="!TaskToDoUpdate.done"></i>
                <i class="icon-checkbox-checked2 text-success" v-if="TaskToDoUpdate.done"></i>
              </label>
            </div>
            <div>
              <input
                :class="'w-100 d-inline border-0 title py-1 rounded px-1 input-task-name detail-name-'+TaskToDoUpdate.id"
                type="text"
                v-model="TaskToDoUpdate.name"
                @blur="UpdateElementTod({id: TaskToDoUpdate.id, key: 'name', value: TaskToDoUpdate.name}, $event)"
              />
            </div>
            <div>
              <label class="title-icon text-yellow cursor-pointer mx-2 mb-0">
                <input type="checkbox" v-model="TaskToDoUpdate.favorite" class="d-none" @change="UpdateElementTod({id: TaskToDoUpdate.id ,key: 'favorite', value: TaskToDoUpdate.favorite}, $event)">
                <i class="icon-star-empty3" v-if="!TaskToDoUpdate.favorite"></i>
                <i class="icon-star-full2" v-if="TaskToDoUpdate.favorite"></i>
              </label>
            </div>
          </div>
        </div>
        <div class="card-body p-2">

          <div class="datepick input-group mb-2 cursor-pointer">
            <div class="input-group-prepend">
              <span class="input-group-text" data-toggle="tooltip" :title="$t(module+'.form.placeholder.date')"><i class="icon-calendar22"></i></span>
            </div>
            <v-date-picker
              mode='range'
              :locale="$i18n.locale"
              v-model='selectedDate'
              :popover="{
                  'placement': 'left'
              }"
            >
              <div slot-scope='props' class="">
                <div
                  v-html="formatSelectedDate(props.inputValue, $t(module+'.form.placeholder.date'))"
                  contenteditable="true"
                  :class="{ 'form-control form-control-lg bg-white px-2': true }"
                  :placeholder="$t('todos.due_date_placeholder')"
                  @change='props.updateValue($event.target.value)'
                >
                </div>
              </div>
            </v-date-picker>
          </div>

          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <span class="input-group-text" data-toggle="tooltip" :title=" $t('todos.responsible')"><i class="icon-user"></i></span>
            </div>
            <div class="cursor-pointer form-control form-control-lg bg-white px-2 user-dropdown-link dropdown-toggle caret-0" data-toggle="dropdown">
              <span v-if="TaskToDoUpdate.owner != -1 && TaskUser(TaskToDoUpdate.owner)[0]">{{ TaskUser(TaskToDoUpdate.owner)[0].rendered_name }}</span>
              <span class="text-grey" v-else>{{ $t('todos.responsible') }}</span>
              <span v-if="TaskToDoUpdate.owner == -1">{{ $t(module+'.monde_users') }}</span>
            </div>
            <div class="dropdown-menu py-1">
              <template v-for="(user, index) in users">
                <a href="#" class="dropdown-item" :key="index" @click="responsibleTask(user, $event)">
                  <img :src="user.avatar.thumb" class="user-img circle" v-if="user.avatar.thumb">
                  <div class="user-badge mr-2 text-uppercase badge badge-light font-weight-bold circle bg-grey" v-if="!user.avatar.thumb">
                    {{ getInitials(user.rendered_name) }}
                  </div>
                  {{ user.rendered_name }}
                </a>
              </template>
              <hr class="my-1">
              <a href="#" class="dropdown-item text-grey" @click="responsibleTask(null, $event)">
                <i class="icon-user pl-1"></i>
                {{ $t('common.clear') }}
              </a>
            </div>
          </div>

          <div class="input-group mb-2 cursor-pointer input-tag">
            <el-select  v-if="dataIsLoad" v-model="tagAction.tag" :options="tags.tag" :multiple="true" :placeholder="$t(module+'.form.list_placeholder.tag')" :keys="{value: 'id', title: 'name'}" class="input-group mb-2 cursor-pointer trigger-Tag"
              :tags="true" @input="selectTag">
            </el-select>
          </div>

          <div class="input-group mb-2 cursor-pointer input-tag">
            <el-select  v-if="dataIsLoad" v-model="tagAction.project" :options="tags.project" :multiple="true" :placeholder="$t(module+'.form.list_placeholder.project')" :keys="{value: 'id', title: 'name'}"
              class="input-group mb-2 cursor-pointer trigger-Project" :tags="true" @input="selectProject">
            </el-select>
          </div>

          <textarea class="note form-control w-100" :placeholder="$t('todos.description')" v-model="TaskToDoUpdate.description" @input="UpdateElementTod({id: TaskToDoUpdate.id, key: 'description', value: TaskToDoUpdate.description}, $event)">
          </textarea>

          <span class="btn btn-link text-right d-block btn-sm px-0 text-grey" @click="advanced_fields = !advanced_fields">
            {{ $t(module+'.form.advanced_fields') }}
            <i class="icon-arrow-down5" v-if="!advanced_fields"></i>
            <i class="icon-arrow-up5" v-else></i>
          </span>

          <div v-if="advanced_fields">

            <div class="input-group mb-2 cursor-pointer">
              <div class="input-group-prepend">
                <span class="input-group-text" data-toggle="tooltip" :title="$t('todos.priority')">
                  <i :class="priorities['low'].icon" v-if="!priorities[TaskToDoUpdate.priority]"></i>
                  <i :class="priorities[TaskToDoUpdate.priority].icon + ' text-' + priorities[TaskToDoUpdate.priority].class" v-if="priorities[TaskToDoUpdate.priority]"></i>
                </span>
              </div>
              <div class="cursor-pointer form-control form-control-lg bg-white px-2 time-dropdown-link dropdown-toggle caret-0" data-toggle="dropdown">
                <span v-if="priorities[TaskToDoUpdate.priority]">{{ priorities[TaskToDoUpdate.priority].title }}</span>
                <span class="text-grey" v-if="!priorities[TaskToDoUpdate.priority]">{{ $t('todos.priority') }}</span>
              </div>
              <div class="dropdown-menu py-1">
                <a href="#" class="dropdown-item" v-for="(priority, index) in priorities" :key="index" @click="prioritiseTask(data, index, $event)">
                  <i :class="priority.icon + ' text-' + priority.class"></i>
                  {{ priority.title }}
                </a>
                <hr class="my-1">
                <a href="#" class="dropdown-item text-grey" @click="prioritiseTask(data, null, $event)">
                  {{ $t('common.clear') }}
                </a>
              </div>
            </div>

            <div class="input-group mb-2 cursor-pointer">
              <div class="input-group-prepend">
                <span class="input-group-text" data-toggle="tooltip" :title="$t(module+'.form.placeholder.url')"><i class="icon-link"></i></span>
              </div>
                <input
                  class="form-control form-control-lg bg-white px-2"
                  :placeholder="$t(module+'.form.placeholder.url')"
                  @change="UpdateTextField(TaskToDoUpdate.id, 'url', $event.target.value)"
                  :value="TaskToDoUpdate.url || ''"
                />
            </div>

            <div class="input-group mb-2 cursor-pointer">
              <div class="input-group-prepend">
                <span class="input-group-text" data-toggle="tooltip" :title="$t('todos.time')"><i class="icon-watch2"></i></span>
              </div>
              <div class="cursor-pointer form-control form-control-lg bg-white px-2 time-dropdown-link dropdown-toggle caret-0" data-toggle="dropdown">
                <span v-if="TaskToDoUpdate.timeToDo">{{ TaskToDoUpdate.timeToDo.time + ' ' + $t(module+'.temps_prevu.' + TaskToDoUpdate.timeToDo.type) }}</span>
                <span class="text-grey" v-if="!TaskToDoUpdate.timeToDo">{{ $t('todos.time') }}</span>
              </div>
              <div class="dropdown-menu py-1">
                <a href="#" class="dropdown-item" v-for="(time, index) in times" :key="index" @click="timeTask(time, $event)">
                  {{ time.time }} {{ $t(module+'.temps_prevu.'+time.type) }}
                </a>
                <hr class="my-1">
                <a href="#" class="dropdown-item text-grey" @click="timeTask( null, $event)">
                  {{ $t('common.clear') }}
                </a>
              </div>
            </div>
<!--             <template>
              <v-date-picker
                :locale="$i18n.locale"
                v-model='data.reminderDate'
                :popover="{
                    'placement': 'left'
                }"
              >
                <div slot-scope='props' class="">
                  <div
                    v-html="formatSelectedDate(props.inputValue, $t(module+'.form.placeholder.date'))"
                    contenteditable="true"
                    :class="{ 'form-control form-control-lg bg-white px-2': true }"
                    :placeholder="$t('todos.reminder_placeholder')"
                    @change.native='props.updateValue($event.target.value)'
                  >
                  </div>
                </div>
              </v-date-picker>
            </template> -->

            <PlacesTodo :position="data.place" :id="data.id"></PlacesTodo>
<!--
            <template>
              <div class="input-group mb-2 cursor-pointer">
                <div class="input-group-prepend">
                  <span class="input-group-text" data-toggle="tooltip" :title="$t(module+'.form.placeholder.recurrence')"><i class="icon-sort-time-asc"></i></span>
                </div>
                <input type="text" :value="recurrence" :placeholder="$t(module+'.form.placeholder.recurrence')"
                :class="{'form-control form-control-lg bg-white px-2': true,'hidden-reccurent': !(data.actionOrigin == null || data.actionOrigin == '') }" @click="openModelRecurrent($event)" readonly>
              </div>
            </template>
 -->
  <!--           <template>
              <div class="input-group mb-2 cursor-pointer">
                <div class="input-group-prepend">
                  <span class="input-group-text" data-toggle="tooltip" :title="$t(module+'.form.placeholder.weight')"><i class="icon-list"></i></span>
                </div>
                <input type="number" v-model="TaskToDoUpdate.position" min="1" @keyup.enter="changePosition()" :placeholder="$t(module+'.form.placeholder.weight')" class="form-control form-control-lg bg-white px-2">
              </div>
            </template> -->

            <div class="input-group mb-2 cursor-pointer input-tag">
              <el-select  v-if="dataIsLoad" v-model="tagAction.list" :options="tags.list" :multiple="true" :placeholder="$t(module+'.form.list_placeholder.list')" :keys="{value: 'id', title: 'name'}"
                class="input-group mb-2 cursor-pointer trigger-List" :tags="true"  @input="selectList">
              </el-select>
            </div>

<!--             <div class="input-group mb-2 cursor-pointer input-tag">
              <el-select v-if="dataIsLoad"  v-model="TaskToDoUpdate.tribus" :options="tags.tribus" :multiple="true" :placeholder="$t(module+'.form.list_placeholder.tribus')" :keys="{value: 'id', title: 'name'}" class="input-group mb-2 cursor-pointer"
                @input="selectTribus">
              </el-select>
            </div> -->
  <!--
            <div class="input-group mb-2 cursor-pointer input-tag">
              <el-select  v-if="dataIsLoad" v-model="tagAction.model" :options="tags.model" :multiple="true" :placeholder="$t(module+'.form.list_placeholder.model')" :keys="{value: 'id', title: 'name'}"
                class="input-group mb-2 cursor-pointer trigger-Model" :tags="true" @input="selectModel">
              </el-select>
            </div> -->


          </div>

          <Comments :comments="data.comment" :id="TaskToDoUpdate.id"></Comments>

          <div class="row mt-2">
            <div class="col-6">
              <i class="icon-arrow-left8 cursor-pointer p-2" data-toggle="tooltip" :title="$t('todos.close_details')" @click="unselectTask()"></i>
            </div>
            <div class="col-6 text-right">
              <i class="icon-bin cursor-pointer p-2" data-toggle="tooltip" :title="$t(module+'.todos.delete')" @click="TaskToRemove = TaskToDoUpdate; openModal('action-board-remove')"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-modal size="sm" id="action-board-remove" :title="$t(module+'.modal.remove_action')">
      <form @submit.prevent="deleteTask()" class="text-center">
        <div v-if="!action_loading" class="form-check mb-3">
          <label class="form-tribescheck-label">
            <input type="checkbox" required class="form-check-input" v-model="remove_action">
            {{ $t(module+'.form.remove_action') }}
          </label>
        </div>
        <div class="text-center text-center">
          <button v-if="!action_loading" type="submit" class="btn btn-lg btn-danger text-white">{{ $t(module+'.form.remove') }}</button>
          <div v-if="action_loading" class="text-grey pt-1 pb-2">
            <loader />
          </div>
        </div>
      </form>
    </el-modal>
    <Recurrent v-if='data.actionOrigin == null || data.actionOrigin == ""' size="xl" id="action-board-recurrent" :item="data" @updateRecurrence="UpdateElementTodoRecurrence"></Recurrent>
  </div>
</template>

<script>

const $ = require('jquery')
window.jQuery = $;
window.$ = $;
import store from '@/store'
import moment from 'moment'

import sousComponents from './sousComponents'

import {
  ACTION_REQUEST_ACTIONS_UPDATE,
  ACTION_REQUEST_ACTIONS_DELETE,
  ACTION_REQUEST_ACTIONS_UPDATE_DATE,
  ACTION_STORE_TAGS
} from '../../mutations-types'
/**
 * @group _ Module Actionsboard
 * This component display detail for action
 */
export default {
  tag: 'el-todo-detail',
  name: 'Todo-detail',
  /**
  * @vuese
  * action to show detail action
  * @type object
  */
  props: ['data', 'priorities', 'formatSelectedDate', 'users'],
  components: {
    Recurrent: sousComponents.Recurrent,
    Comments: sousComponents.Comments,
    PlacesTodo: sousComponents.PlacesTodo,
  },
  data() {
    return {
      mounted: false,
      advanced_fields: false,
      action_loading: false,
      userAuth: store.getters['auth/getProfile'].id,
      formError: '',
      worldId: store.getters['auth/getWorld'].id,
      times: [{
          time: 5,
          type: 'minutes'
        },
        {
          time: 15,
          type: 'minutes'
        },
        {
          time: 30,
          type: 'minutes'
        },
        {
          time: 1,
          type: 'heure'
        },
        {
          time: 2,
          type: 'heures'
        },
        {
          time: 4,
          type: 'heures'
        },
      ],
      module: 'modules.' + this.$route.meta.moduleName,
      tags: {
        tag: [],
        list: [],
        project: [],
        model: [],
        tribus: [],
      },
      tagAction: {
        tag: [],
        list: [],
        project: [],
        model: [],
        tribus: [],
      },
      stokeTags: {
        tag: [],
        list: [],
        project: [],
        model: [],
        tribus: [],
      },
      TaskToDoUpdate: {},
      TaskToRemove: {},
      remove_action: false,
      recurrence: null,
      dataIsLoad: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.mounted = true
    }, 400)

    // Tooltip
    this.$nextTick(function() {
      $('[data-toggle="tooltip"]').each(function() {
        if ($(this).attr('title') === '') {
          $(this).attr('title', $(this).next('.tooltip').html())
        }
      }).tooltip({
        html: true,
        placement: 'bottom'
      }).each(function() {
        $(this).attr('title', '')
      })
    })
    this.TaskToDoUpdate = this.$store.state.actionsboard.todos.filter(item => item.id == this.data.id)

    this.TaskToDoUpdate = this.TaskToDoUpdate[0]
    this.tagAction.tag = this.stokeTags.tag = this.TaskToDoUpdate.tag
    this.tagAction.list = this.stokeTags.list = this.TaskToDoUpdate.list
    this.tagAction.project = this.stokeTags.project = this.TaskToDoUpdate.project
    this.tagAction.model = this.stokeTags.model = this.TaskToDoUpdate.model
    this.tagAction.tribus = this.stokeTags.tribus = this.TaskToDoUpdate.tribes

    this.recurrence = (this.TaskToDoUpdate.recurrence != null && this.TaskToDoUpdate.recurrence != '' && Array.isArray(this.TaskToDoUpdate.recurrence) && this.TaskToDoUpdate.recurrence.length > 0) ? this.TaskToDoUpdate.recurrence[0] : [null]

    this.tags.tag = this.$store.state.actionsboard.tagsTag
    this.tags.list = this.$store.state.actionsboard.tagsList
    this.tags.project = this.$store.state.actionsboard.tagsProject
    this.tags.model = this.$store.state.actionsboard.tagsModel
    this.tags.tribus = this.$store.state.actionsboard.tagsTribes
    this.dataIsLoad = true

  },
  destroyed() {
    // Tooltip
    $('.tooltip').remove()
  },
  computed : {
    selectedDate: {
      get: function () {
        return {
          start: this.data.startDate ? moment(this.data.startDate).toDate() : null,
          end: this.data.endDate ? moment(this.data.endDate).toDate() : null,
        }
      },
      set: function (newValue) {
        if (this.mounted) {
          if (
            moment(this.data.startDate).format() != moment(newValue.start).format() ||
            moment(this.data.endDate).format() != moment(newValue.end).format()
          ) {
            this.UpdateDate(
              this.data.id,
              moment(newValue.start).format(),
              moment(newValue.end).format(),
            )
          }
        }
      }
    }
  },
  methods: {
    /**
     * @vuese
     *   Check on unchcked task
     * @arg
     */
    checkTask(e) {
      this.TaskToDoUpdate.done = e.target.checked
      this.UpdateElementTod({
        id: this.TaskToDoUpdate.id,
        key: 'done',
        value: this.TaskToDoUpdate.done
      }, e)
    },
    UpdateTextField (itemId, fieldKey, value) {
      this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE, {
        id: this.worldId,
        entity: 'Actions',
        action: {
          id: itemId,
          key: fieldKey,
          value: value,
        },
      }).then(() => {
        this.$store.commit('actionsboard/refreshTodo', {
          id: itemId,
          key: fieldKey,
          value: value,
        })
      })
    },
    UpdateDate (itemId, startDate, endDate) {
      this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE_DATE, {
        id: this.worldId,
        entity: 'Actions',
        action: {
          id: itemId,
          startDate: startDate,
          endDate: endDate,
        },
      }).then(() => {
        this.$store.commit('actionsboard/refreshTodo', {
          id: itemId,
          key: 'startDate',
          value: startDate,
        })
        this.$store.commit('actionsboard/refreshTodo', {
          id: itemId,
          key: 'endDate',
          value: endDate,
        })
      })
    },
    /**
     * @vuese
     * Set the existing task reponsible
     * @arg
     */

    responsibleTask(user = null, e) {
      e.preventDefault()
      if (user != null && user != -1) {
        this.TaskToDoUpdate.owner = user.id
      } else if (user == null && user != -1) {
        this.TaskToDoUpdate.owner = this.TaskToDoUpdate.creator
      } else if (user == -1) {
        this.TaskToDoUpdate.owner
      }
      this.UpdateElementTod({
        id: this.TaskToDoUpdate.id,
        key: 'owner',
        value: this.TaskToDoUpdate.owner
      }, e)
    },
    /**
     * @vuese
     * Set the existing task time
     * @arg
     */

    timeTask(time, e) {
      e.preventDefault()
      this.TaskToDoUpdate.timeToDo = time
      this.UpdateElementTod({
        id: this.TaskToDoUpdate.id,
        key: 'timeToDo',
        value: this.TaskToDoUpdate.timeToDo
      }, e)
    },
    /**
     * @vuese
     * Set the existing task priority
     * @arg
     */

    prioritiseTask(task, priority, e) {
      e.preventDefault()
      task.priority = priority
      this.UpdateElementTod({
        id: this.TaskToDoUpdate.id,
        key: 'priority',
        value: this.TaskToDoUpdate.priority
      }, e)
    },
    /**
     * @vuese
     * this function to unselect action hide component detail
     * @arg
     */

    unselectTask() {
      this.$emit('unselectTask', {}, false)
    },
    /**
     * @vuese
     * This function to delect action
     * @arg
     */

    deleteTask() {
      this.action_loading = true;
      if (this.remove_action) {
        this.$store.dispatch('actionsboard/' + ACTION_REQUEST_ACTIONS_DELETE, {
          id: this.worldId,
          entity: 'Actions',
          action: this.TaskToRemove.id
        }).then((resp) => {
          this.notifSuccess(resp)
          this.$store.commit('actionsboard/deleteTodo', this.TaskToRemove.id)
          this.action_loading = false
          this.closeModal('action-board-remove')
          this.unselectTask()
        })
      }

    },
    /**
     * @vuese
     * select User
     * @arg integer
     */

    TaskUser(user) {
      return this.users.filter(item => {
        if (item.id == user) {
          return item
        }
      })
    },
    /**
     * @vuese
     *  this function to update any item in object action object passe params {key, val id}
     * @arg Object
     */

    UpdateElementTod(object, e = null) {
      if (e != null) {
        e.preventDefault()
      }
      if (object && object.value) {
        this.$store.dispatch('actionsboard/' + ACTION_REQUEST_ACTIONS_UPDATE, {
          id: this.worldId,
          entity: 'Actions',
          action: object
        }).then(() => {
          this.$emit('updateTodo', object)
        })
      }

    },

    /**
     * @vuese
     * this function update recurrent data
     * @arg
     */

    UpdateElementTodoRecurrence(object) {
      this.$store.dispatch('actionsboard/' + ACTION_REQUEST_ACTIONS_UPDATE, {
        id: this.worldId,
        entity: 'Actions',
        action: object
      }).then((resp) => {
        this.closeModal('action-board-recurrent')
        this.notifSuccess(resp[0])
        this.$store.commit('actionsboard/refreshTodo', object)
        this.$store.commit('actionsboard/pushTodos', resp[1])
        this.$emit('updateTodoData')
      })
    },
    /**
     * @vuese
     * Open modal recurrent
     * @arg
     */

    openModelRecurrent(e) {
      e.preventDefault()
      this.openModal('action-board-recurrent')
    },
    /**
     * @vuese
     * Select tag type tag
     * @arg
     */

    selectTag(value) {
      if(value.length < this.stokeTags['tag'].length && this.stokeTags['tag'].length > 0 ) {
        let object = {
          id: this.TaskToDoUpdate.id,
          key: 'tag',
          value: value
        }
        this.$store.commit('actionsboard/refreshTodo', {
          id: this.TaskToDoUpdate.id,
          key: 'tag',
          value: value,
          delete: true
        })
        this.UpdateElementTod(object, null)
        this.tagAction[object.key] = value
        this.stokeTags[object.key] = value
        return
      }
      let elem = null
      if(this.stokeTags['tag'].length > 0) {
          value.map(item => {
            let data = this.stokeTags['tag'].filter(v => v == item)
            if(data.length == 0) {
              elem = item
            }
          })
      } else {
        elem = value [0]
      }
      let exist = this.$store.state.actionsboard.tagsTag.filter(item => item.id == elem)
      if(exist.length > 0) {
        let object = {
          id: this.TaskToDoUpdate.id,
          key: 'tag',
          value: value
        }
        this.UpdateElementTod(object, null)
        this.$store.commit('actionsboard/refreshTodo', {
          id: this.TaskToDoUpdate.id,
          key: 'tag',
          value: elem
        })
        this.tagAction[object.key] = value
        this.stokeTags[object.key] = value
      } else if(exist.length == 0) {
        this.createNewTag('Tag', elem, 'tag')
      }
    },
    /**
     * @vuese
     * Select tag type list
     * @arg
     */

    selectList(value) {
      if(value.length < this.stokeTags['list'].length && this.stokeTags['list'].length > 0 ) {
        let object = {
          id: this.TaskToDoUpdate.id,
          key: 'list',
          value: value
        }
        this.$store.commit('actionsboard/refreshTodo', {
          id: this.TaskToDoUpdate.id,
          key: 'list',
          value: value,
          delete: true
        })
        this.UpdateElementTod(object, null)
        this.tagAction[object.key] = value
        this.stokeTags[object.key] = value
        return
      }
      let elem = null
      if(this.stokeTags['list'].length > 0) {
        value.map(item => {
          let data = this.stokeTags['list'].filter(v => v == item)
          if(data.length == 0) {
            elem = item
          }
        })
      } else {
        elem = value [0]
      }
      let exist = this.$store.state.actionsboard.tagsList.filter(item => item.id == elem)
      if(exist.length > 0) {
        let object = {
          id: this.TaskToDoUpdate.id,
          key: 'list',
          value: value
        }
        this.UpdateElementTod(object, null)
        this.$store.commit('actionsboard/refreshTodo', {
          id: this.TaskToDoUpdate.id,
          key: 'list',
          value: value[value.length - 1]
        })
        this.tagAction[object.key] = value
        this.stokeTags[object.key] = value
      } else if(exist.length == 0) {
        this.createNewTag('List', elem, 'list')
      }
    },
    /**
     * @vuese
     * Select tag type project
     * @arg
     */

    selectProject(value) {
      if(value.length < this.stokeTags['project'].length && this.stokeTags['project'].length > 0 ) {
        let object = {
          id: this.TaskToDoUpdate.id,
          key: 'project',
          value: value
        }
        this.$store.commit('actionsboard/refreshTodo', {
          id: this.TaskToDoUpdate.id,
          key: 'project',
          value: value,
          delete: true
        })
        this.UpdateElementTod(object, null)
        this.tagAction[object.key] = value
        this.stokeTags[object.key] = value
        return
      }
      let elem = null
      if(this.stokeTags['project'].length > 0) {
        value.map(item => {
          let data = this.stokeTags['project'].filter(v => v == item)
          if(data.length == 0) {
            elem = item
          }
        })
      } else {
        elem = value [0]
      }
      let exist = this.$store.state.actionsboard.tagsProject.filter(item => item.id == elem)
      if(exist.length > 0) {
        let object = {
          id: this.TaskToDoUpdate.id,
          key: 'project',
          value: value
        }
        this.UpdateElementTod(object, null)
        this.$store.commit('actionsboard/refreshTodo', {
          id: this.TaskToDoUpdate.id,
          key: 'project',
          value: value[value.length - 1]
        })
        this.tagAction[object.key] = value
        this.stokeTags[object.key] = value
      } else if(exist.length == 0) {
        this.createNewTag('Project', elem, 'project')
      }
    },
    /**
     * @vuese
     * Select tag Model
     * @arg
     */

    selectModel(value) {
      if(value.length < this.stokeTags['model'].length && this.stokeTags['model'].length > 0 ) {
        let object = {
          id: this.TaskToDoUpdate.id,
          key: 'model',
          value: value
        }
        this.$store.commit('actionsboard/refreshTodo', {
          id: this.TaskToDoUpdate.id,
          key: 'model',
          value: value,
          delete: true
        })
        this.UpdateElementTod(object, null)
        this.tagAction[object.key] = value
        this.stokeTags[object.key] = value
        return
      }
      let elem = null
      if(this.stokeTags['model'].length > 0) {
        value.map(item => {
          let data = this.stokeTags['model'].filter(v => v == item)
          if(data.length == 0) {
            elem = item
          }
        })
      } else {
        elem = value [0]
      }
      let exist = this.$store.state.actionsboard.tagsModel.filter(item => item.id == elem)
      if(exist.length > 0) {
        let object = {
          id: this.TaskToDoUpdate.id,
          key: 'model',
          value: value
        }
        this.UpdateElementTod(object, null)
        this.$store.commit('actionsboard/refreshTodo', {
          id: this.TaskToDoUpdate.id,
          key: 'model',
          value: value[value.length - 1]
        })
        this.tagAction[object.key] = value
        this.stokeTags[object.key] = value
      } else if(exist.length == 0) {
        this.createNewTag('Model', elem, 'model')
      }
    },
    /**
     * @vuese
     * Select tribus
     * @arg
     */

    selectTribus(value) {
      this.UpdateElementTod({
        id: this.TaskToDoUpdate.id,
        key: 'tribus',
        value: value
      }, null)
    },
    /**
     * @vuese
     * Create new tag
     * @arg
     */

    createNewTag(type, tag, entity) {
      let object = {
        name: tag,
        type: type,
        worldId: this.worldId,
        actionOrigin: this.TaskToDoUpdate.id
      }
      this.$store.dispatch('actionsboard/' + ACTION_STORE_TAGS, {
        id: this.worldId,
        entity: 'Tags',
        type: type,
        tag: object
      }).then((resp) => {
        this.notifSuccess(resp[0])
        this.closeModal('action-board-new')
        this.tags[entity].unshift(...resp[1])
        this.TaskToDoUpdate[type.toLowerCase()].push(resp[1][0].id)
        let objectTag = {
          id: this.TaskToDoUpdate.id,
          key: type.toLowerCase(),
          value: this.TaskToDoUpdate[type.toLowerCase()]
        }
        this.UpdateElementTod(objectTag, null)
      })
    },

    /**
     * @vuese
     * Update position action
     * @arg
     */

    changePosition () {
      let object = {
        id: this.TaskToDoUpdate.id,
        key: 'position',
        value: this.TaskToDoUpdate.position
      }
      this.UpdateElementTod(object, null)
      this.$store.commit('actionsboard/refreshTodo', object)
      $('.taskSelectedIs_'+this.TaskToDoUpdate.id).addClass('detailsIsShow')
      this.notifSuccess(this.$t(this.module+'.change_position'))
    },
    /**
     * @vuese
     * add tag to list tagaction
     * @arg
     */

    AddTagCreate (data) {
      this.tagAction[data.type].push(data.id)
    },
    /**
     * @vuese
     * add tag to list tagaction
     * @arg
     */
    refreshTags () {
      this.tags.tag = this.$store.state.actionsboard.tagsTag
      this.dataIsLoad = false
      this.$nextTick(() => {
       this.dataIsLoad = true
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.title-icon {
    position: relative;
    z-index: 2;
}
.title {
  background: none;
    &:focus,
    &:hover {
        background: rgba(black, .05);
    }
}
.note {
    background: lighten(yellow, 45%);
}
.hidden-reccurent {
    background-color: #ddd !important;
}
#filters {
  margin: 0 auto;
  position: relative;
  width: 100%;
}
#todoDetailsWrapper {
  top: 52px;
  z-index: 4;
  position: sticky;
  max-height: 85%;
}
.place-input {
    .algolia-places {
        flex: 1 1 auto;
        width: 1%;
    }
}

/deep/ .algolia-places,
.datepick.input-group .input-group-prepend + span,
.datepick.input-group .input-group-prepend + div {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  .form-control {
    border-radius: 0;
  }
}
</style>
