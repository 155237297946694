import TodoCreate from './_TodoCreate'
import TagData from './_TagData'
import TodoActionsList from './_TodoActionsList'
import TodoActionsKanban from './_TodoActionsKanban'
import TodoActionsCalendar from './_TodoActionsCalendar'
import TodoActionsGantt from './_TodoActionsGantt'
import TodoDetail from './_TodoDetail'
import FastActions from './_FastActions'


export default {
    TodoCreate,
    TagData,
    TodoActionsList,
    TodoActionsKanban,
    TodoActionsCalendar,
    TodoActionsGantt,
    TodoDetail,
    FastActions,
}
