var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auth-content',[_c('el-header',{attrs:{"slot":"header","title":_vm.$t(_vm.module + '.title'),"permissions":"actionsboard"},slot:"header"},[(_vm.actionsLoaded && _vm.actions && _vm.actions.length > 0)?_c('div',{attrs:{"slot":"actions"},slot:"actions"},[_c('div',{staticClass:"btn-group btn-group-sm"},[_c('button',{class:{
            'btn border-0 auto-radius text-white': true,
            'active btn-primary text-white': _vm.actionViewMode == 'list',
            'btn-secondary': _vm.actionViewMode != 'list',
          },attrs:{"type":"button"},on:{"click":function($event){return _vm.$store.commit('actionsboard/setActionViewMode', 'list')}}},[_c('i',{staticClass:"mr-1 d-none d-md-inline icon-list3"}),_vm._v(" "+_vm._s(_vm.$t(_vm.module + '.header.button_list_view')))]),_c('button',{class:{
            'btn border-0 auto-radius text-white': true,
            'active btn-primary': _vm.actionViewMode == 'kanban',
            'btn-secondary': _vm.actionViewMode != 'kanban',
          },attrs:{"type":"button"},on:{"click":function($event){return _vm.$store.commit('actionsboard/setActionViewMode', 'kanban')}}},[_c('i',{staticClass:"mr-1 d-none d-md-inline icon-windows2"}),_vm._v(" "+_vm._s(_vm.$t(_vm.module + '.header.button_kanban_view')))]),_c('button',{class:{
            'btn border-0 auto-radius text-white': true,
            'active btn-primary': _vm.actionViewMode == 'calendar',
            'btn-secondary': _vm.actionViewMode != 'calendar',
          },attrs:{"type":"button"},on:{"click":function($event){return _vm.$store.commit('actionsboard/setActionViewMode', 'calendar')}}},[_c('i',{staticClass:"mr-1 d-none d-md-inline icon-calendar22"}),_vm._v(" "+_vm._s(_vm.$t(_vm.module + '.header.button_calendar_view')))]),_c('button',{class:{
            'btn border-0 auto-radius text-white': true,
            'active btn-primary': _vm.actionViewMode == 'gantt',
            'btn-secondary': _vm.actionViewMode != 'gantt',
          },attrs:{"type":"button"},on:{"click":function($event){return _vm.$store.commit('actionsboard/setActionViewMode', 'gantt')}}},[_c('i',{staticClass:"mr-1 d-none d-md-inline icon-paragraph-right3\n"}),_vm._v(" "+_vm._s(_vm.$t(_vm.module + '.header.button_gantt_view')))])])]):_vm._e()]),(_vm.actionsLoaded && _vm.actions && _vm.actions.length > 1)?_c('el-filters',{attrs:{"filters":_vm.filters}}):_vm._e(),_c('div',{},[_c('TodoCreate',{ref:"childCreate",attrs:{"users":_vm.users,"priorities":_vm.priorities,"formatSelectedDate":_vm.formatSelectedDate}}),(!_vm.actionsLoaded)?_c('div',[_c('div',{staticClass:"pt-2"},_vm._l((7),function(i){return _c('div',{staticClass:"falseaction card bg-white mb-2 align-items-center justify-content-between d-flex",style:('animation-delay: .'+(i)+'s')},[_c('div',{staticClass:"bg-secondary falsecheckbox"}),_c('div',{staticClass:"bg-secondary falsetext"}),_c('div',{staticClass:"align-items-center justify-content-between d-flex"},[_c('div',{staticClass:"bg-secondary falsepeople"}),_c('i',{staticClass:"icon-star-full2 text-secondary mx-3"})])])}),0)]):(_vm.actionsLoaded && _vm.actions && _vm.actions.length > 0)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{class:{
              'col-md-8 col-xl-9 col-xxl-10': _vm.onShowDetail,
              'col-12': !_vm.onShowDetail,
            }},[(_vm.actionViewMode === 'list')?_c('div',[_c('TodoActionsList',{attrs:{"formatSelectedDate":_vm.formatSelectedDate,"actions":_vm.filteredActions,"pdca_values":_vm.pdca_values,"priorities":_vm.priorities,"users":_vm.users},on:{"onShowDetailTodo":_vm.onShowDetailTodo}})],1):_vm._e(),(_vm.actionViewMode === 'kanban')?_c('div',[_c('TodoActionsKanban',{attrs:{"actions":_vm.filteredActions,"pdca_values":_vm.pdca_values,"priorities":_vm.priorities,"users":_vm.users},on:{"onShowDetailTodo":_vm.onShowDetailTodo}})],1):_vm._e(),(_vm.actionViewMode === 'calendar')?_c('div',[_c('TodoActionsCalendar',{attrs:{"actions":_vm.filteredActions,"pdca_values":_vm.pdca_values,"priorities":_vm.priorities,"users":_vm.users},on:{"onShowDetailTodo":_vm.onShowDetailTodo}})],1):_vm._e(),(_vm.actionViewMode === 'gantt')?_c('div',[_c('TodoActionsGantt',{attrs:{"actions":_vm.filteredActions,"pdca_values":_vm.pdca_values,"priorities":_vm.priorities,"users":_vm.users},on:{"onShowDetailTodo":_vm.onShowDetailTodo}})],1):_vm._e()]),(_vm.onShowDetail)?_c('div',{class:{
              'col-md-4 col-xl-3 col-xxl-2': true,
            }},[(_vm.tagsLoaded)?_c('TodoDetail',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutsideTask),expression:"clickOutsideTask"}],ref:"childDetail",attrs:{"data":_vm.dataDetailListing,"users":_vm.users,"priorities":_vm.priorities,"formatSelectedDate":_vm.formatSelectedDate},on:{"updateTodo":_vm.updateTodo,"updateTodoData":_vm.updateTodoData,"unselectTask":_vm.onShowDetailTodo}}):_vm._e()],1):_vm._e()])]):_c('el-empty',{attrs:{"title":_vm.$t(_vm.module + '.actions.empty_title'),"text":_vm.$t(_vm.module + '.actions.empty_text')}})],1),_c('TagData',{on:{"changeResponsable":_vm.changeResponsable,"datepickerNewTodoEmit":_vm.datepickerNewTodoEmit,"AddTagEmit":_vm.AddTagEmit,"refreshDataTags":_vm.refreshDataTags}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }