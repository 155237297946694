<!-- Compoenent actions  -->
<template>
  <div class="">

    <kanban-board :stages="pdca_values" :blocks="actionsFormatted" class="kanban mb-2" @update-block="updateTaskStatus">
      <div class="" v-for="stage in pdca_values" :slot="stage" :key="stage">
        <div class="card shadow-0">
          <div class="card-body p-2">
            <div class="font-weight-bold m-0 d-flex">
              <Pdca :value='stage'></Pdca>
              <div class="ml-2">{{ $t(module + '.pdca.' + stage) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card mb-2"
        v-for="block in actionsFormatted"
        :key="block.id"
        :slot="block.id"
        @click="selectTaskDetails(block)"
      >
        <div class="card-body p-2">
          <div>
            <i :class="priorities[block.priority].icon + ' mr-1 text-' + priorities[block.priority].class"></i>
            <span :class="{ 'text-break': true, 'text-striked': block.done }">{{ block.title }}</span>
          </div>
          <div class="mt-1" v-if="block.startDate || block.owner"></div>
          <div class="d-flex justify-content-end align-items-center">
            <div v-if="block.startDate" class="small text-grey">
              {{ $d(new Date(block.startDate), 'shortText') }}
              <span v-if="block.endDate && block.endDate != block.startDate">
                → {{ $d(new Date(block.endDate), 'shortText') }}
              </span>
            </div>
            <div>
              <div v-if="block.owner">
                <img
                  :src="block.owner.avatar.thumb"
                  class="user-img circle ml-1"
                  v-if="block.owner.avatar.thumb"
                  v-tooltip="{
                    content: block.owner.rendered_name,
                    placement: 'top',
                  }"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </kanban-board>

  </div>
</template>

<script>

import moment from 'moment'
import {ACTION_REQUEST_ACTIONS_UPDATE} from '../../mutations-types'
import sousComponents from './sousComponents'

/**
 * @group _ Module Actionsboard
 * This component display all actions list
 */
  export default {
    name: 'TodoActionsKanban',
    components: {
      Pdca: sousComponents.Pdca
    },
    props: ['actions', 'priorities', 'pdca_values', 'users'],
    data () {
      return {
        module: 'modules.'+this.$route.meta.moduleName,
        filters: [],
        worldId: this.$store.getters['auth/getWorld'].id,
        selectedTaskIs : {},
      }
    },
    methods: {
      updateTaskStatus (id, status) {
        this.actions.find(b => b.id === Number(id)).status = status;
        this.pdcaTask(this.actions.find(b => b.id === Number(id)), status)
      },
      /**
       * @vuese
       * Select Pdca Action
       * @arg object
       */
      pdcaTask (task, pdca) {
        task.pdca = pdca
        let object = {
          key: 'pdca',
          value: task.pdca,
          id: task.id
        }
        this.UpdateElementTod(object)
      },
      /**
       * @vuese
       * fubction to update action item object
       * @arg object
       */
      UpdateElementTod (object) {
        this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE, {id: this.worldId, entity: 'Actions', action: object}).then((resp) => {
          this.$store.commit('actionsboard/refreshTodo', object)
        })
      },
      /**
       * @vuese
       * this function to show detail action selected
       * @arg
       */
      selectTaskDetails (task) {
        if (!(typeof this.selectedTaskIs.id !== 'undefined' && this.selectedTaskIs.id == task.id)) {
          this.selectedTaskIs = task
          this.$emit('onShowDetailTodo', {}, false)
          setTimeout(() => {
            this.$emit('onShowDetailTodo', task, true)
          }, 10)
        }
      },
      //set New DAte Update
      /**
       * @vuese
       * this function change dateStart for action
       * @arg
       */
      chanegDate(elem) {
        this.data.filter(item => {
          if (item.id === elem.id) {
            item.startDate = elem.startDate
          }
        })
      },
    },
    computed: {
      actionsFormatted () {
        return this.actions.map(elem => {
          return {
            id: elem.id,
            title: elem.name,
            status: elem.pdca || 'plan',
            priority: elem.priority || null,
            startDate: elem.startDate || null,
            endDate: elem.endDate || null,
            done: elem.done,
            owner: this.users.find(user => { return user.id == elem.owner }),
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.loading-action {
  text-align: center;
  i {
      font-size: 28px;
  }
}
.badge-title {
  padding: 0.3125rem 0rem 0.3125rem 0rem;
}
.badge-Responsable, .badge-Date {
  float: right;
}
.badge-title, .badge-Titre, .badge-Priorité {
  float: left;
}
.clear {
  clear: both;
}
.badge{
  margin-bottom: .625rem!important;
}
i.icon-sort {
  font-size: 13px;
  margin-bottom: -3px;
}


/deep/ {
  .kanban {
    overflow-x: scroll;
  }
  .drag-list {
    min-width: 600px;
  }
  .drag-item {
    cursor: move;
    transition: all .3s cubic-bezier(.23,1,.32,1);
  }
  .drag-item.is-moving {
    transition: all .3s cubic-bezier(.23,1,.32,1);
    transform: scale(1.02);
    opacity: .8;
  }
  .drag-options {
    position: absolute;
    top: 44px;
    left: 0;
    // width: 100%;
    height: 100%;
    padding: 10px;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
    transition: all .3s cubic-bezier(.23,1,.32,1)
  }
  .drag-options.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  .drag-options-label {
    display: block;
    margin: 0 0 5px;
  }
  .drag-options-label input {
    opacity: .6;
  }
  .drag-options-label span {
    display: inline-block;
    font-size: .9rem;
    font-weight: 400;
    margin-left: 5px;
  }
}

.kanban {
  ul, /deep/ ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  /deep/ > .drag-list {
    display: flex;
    margin: 0 -5px;
    > .drag-column {
      padding: 0px 5px;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .drag-inner-list {
        min-height: 50%;
      }
    }
    .text-break {
      word-break: break-word;
    }
  }
}
.task {
  border-left-width: 3px !important;
  border-top-color: #ddd !important;
  border-right-color: #ddd !important;
  border-bottom-color: #ddd !important;
}
.tags-edit {
  position: relative;
  i {
    position: absolute;
    right: 6px;
    top: 9px;
  }
}
.user-img {
  width: 20px;
  height: 20px;
}
.user-badge {
  position: relative;
  top: -2px;
}
.text-striked {
  text-decoration:line-through;
}
</style>
