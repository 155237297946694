<!-- Component Places vue  -->
<template>
  <div class="fastlist-wrapper" v-if="isCurrentWorldOwner || fastsList.length > 0">

    <div class="fastlist-wrapper-inner mb-3" >
      <div class="d-inline-block align-top" v-for="(fast, fastIndex) in fastsList">
        <div
          class="position-relative card flex-row cursor-pointer fastac rounded bg-white p-2 mr-2 mb-2 d-inline-block d-flex align-items-center text-center"
          @click="!trashHovered ? createAction(fast) : null"
        >
          <span
            class="position-absolute top-0 left-0 p-1 text-grey small d-flex align-items-center"
            v-if="actionsRelated[fast.id] && actionsRelated[fast.id].length > 0"
             v-tooltip="$t('modules.actionsboard.fast.already_asked', {nb: actionsRelated[fast.id].length})"
          >
            <span v-for="action in actionsRelated[fast.id].slice(0,3)" :key="action.id" class="mr-1">
              <el-avatar v-if="getUser(action.data.creator)" :src="getUser(action.data.creator) ? getUser(action.data.creator).avatar.thumb : null" size="xsmall" />
            </span>
            <span class="font-weight-bold" v-if="actionsRelated[fast.id].length > 3">+{{ actionsRelated[fast.id].length - 3 }}</span>
          </span>
          <i
            v-if="isCurrentWorldOwner"
            class="icon-trash-alt position-absolute top-0 right-0 p-2 text-grey small"
            v-tooltip.top="$t('common.dbl_click_delete')"
            @dblclick="deleteFast(fast.id, fastIndex)"
            @mouseenter="trashHovered = true"
            @mouseleave="trashHovered = false"
          />
          <div class="w-100 d-inline">
            <div class="icon-preview mb-2">{{ fast.emoji }}</div>
            <div class="title-preview">{{ fast.name }}</div>
          </div>
        </div>
      </div>

      <div class="d-inline-block align-top">
        <div v-if="isCurrentWorldOwner" class="position-relative fastac rounded bg-white p-2 mr-2 mb-2 d-flex align-items-center text-center">
          <i class="icon-info22 position-absolute top-0 right-0 p-2 small"  v-tooltip.top="$t('modules.actionsboard.fast.new_owner_text')" />
          <div class="w-100 d-inline">
            <div class="w-50 m-auto position-relative" v-click-outside="closeEmoji">
              <span class="cursor-pointer mb-2" @click="showEmojiDialog = !showEmojiDialog">
                <span class="icon-preview">{{ newIcon }}</span>
                <i class="ml-1 icon-arrow-down22"/>
              </span>
              <VEmojiPicker
                v-show="showEmojiDialog"
                @select="selectEmoji"
                emojisByRow="6"
                :emojiWithBorder="false"
              />
            </div>
            <TextareaAutosize
              v-model="newName"
              id="new-fast-action"
              @keydown.native.enter="createFast"
              rows="1"
              :required="true"
              class="w-100 text-center rounded mt-1 title-preview"
              :min-height="0"
              :max-height="112"
              :placeholder="$t('modules.actionsboard.fast.new_placeholder')"
              v-tooltip="{
                content: $t('modules.actionsboard.fast.new_help'),
                placement: 'left',
              }"
            />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import { VEmojiPicker, emojisDefault, categoriesDefault } from 'v-emoji-picker';
import vClickOutside from 'v-click-outside'
import {
  ACTIONBOARD_REQUEST_FAST,
  ACTIONBOARD_UPDATE_FAST,
  ACTIONBOARD_DESTROY_FAST,
  ACTIONBOARD_STORE_FAST,
  ACTION_REQUEST_ACTIONS_STORE,
} from '../../mutations-types'

const defaultIcon = '☕️'
const defaultName = ''

/**
 * @group _ Module Actionsboard
 * This component display fast actions list
 */
export default {
  name: 'FastActions',
  components: {
    VEmojiPicker,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data () {
    return {
      showEmojiDialog: false,
      newName: defaultName,
      newIcon: defaultIcon,
      fastsList: [],
      actionsRelated: {},
      trashHovered: false,
    }
  },
  mounted () {
    if (!this.hasWorld) {
      return
    }
    this.loadFasts()
  },
  computed: {
    hasWorld () {
      return this.$store.getters['auth/hasWorld']
    },
    fastListWidth () {
      let itenWidth = 160
      let nbItems = this.fastsList.length
      if (this.isCurrentWorldOwner) {
        nbItems++
      }
      return nbItems * itenWidth
    },
    currentWorld () {
      return this.$store.getters['auth/getWorld']
    },
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    currentWorldUsers () {
      let users = this.$store.getters['world/getUsers']
      if (!users) {
        users = []
      }
      return users
    },
    currentWorldOwner () {
      return this.currentWorldUsers.find(user => {
        return user.pivot.owner == true
      })
    },
  },
  methods: {
    loadFasts () {
      this.$store.dispatch('actionsboard/' + ACTIONBOARD_REQUEST_FAST, {
        world_id: this.currentWorld.id,
      }).then(fasts => {
        this.fastsList = fasts.data
        this.actionsRelated = fasts.actions_related
      })
    },
    getUser (getId) {
      return this.currentWorldUsers.find(user => {
        return getId == user.id
      })
    },
    createAction (fast) {
      let newToDoTask = {
        name: fast.emoji + ' ' + fast.name,
        owner: this.currentWorldOwner.id,
        creator: this.currentUser.id,
        oldOwner: this.currentUser.id,
        fast: fast.id,
        worldId: this.currentWorld.id,

        startDate: null,
        endDate: null,
        pdca: 'plan',
        priority: 'none',
        favorite: false,
        done: false,
        dueDate: null,
        timeToDo: null,
        reminderDate: null,
        place: null,
        description: null,
        comment: null,
        source: 'local',
        recurrence: null,
        langue: 'fr',
        ownerActivated: false,
        actionOrigin: null,
        actionRecurrence: false,
        position: null,
        project: [],
        tribus: [],
        tag: [],
        list: [],
        model: [],
      }

      this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_STORE, {id: this.currentWorld.id, entity: 'Actions', action: newToDoTask}).then(resp => {
          this.$store.commit('actionsboard/pushTodos', resp[1])

          if (!this.actionsRelated[fast.id]) {
            this.actionsRelated[fast.id] = []
          }

          this.loadFasts()

          this.notifSuccess(resp[0])
      })
    },
    deleteFast (id, index) {
      this.$store.dispatch('actionsboard/' + ACTIONBOARD_DESTROY_FAST, {
        world_id: this.currentWorld.id,
        id: id,
      }).then(fast => {
        this.fastsList.splice(index, 1)
      }).catch(error => {
        this.notifError(error)
      })
    },
    createFast (e) {
      e.preventDefault()
      if (this.newName == '') {
        return
      }
      this.$store.dispatch('actionsboard/' + ACTIONBOARD_STORE_FAST, {
        world_id: this.currentWorld.id,
        name: this.newName,
        emoji: this.newIcon,
      }).then(fast => {
        this.newName = defaultName
        this.newIcon = defaultIcon
        this.fastsList.push(fast)
      }).catch(error => {
        this.notifError(error)
      })
    },
    selectEmoji(emoji) {
      this.newIcon = emoji.data
      this.showEmojiDialog = false
    },
    closeEmoji() {
      this.showEmojiDialog = false
    },
  },
}
</script>

<style lang="scss">
  // .fastlist-wrapper {
  //   overflow-x: scroll;
  // }
  #new-fast-action {
    border: 2px solid #eee;
    &:hover {
      border-color: #ccc;
    }
    &:focus {
      border-color: #bbb;
    }
  }
  .fastac {
    width: 200px;
    height: 200px;
  }
  #EmojiPicker {
    position: absolute;
    z-index: 100;
    right: 100%;
    width: 250px;
    .category {
      padding: 4px;
      border-width: 2px;
      &.active {
        border-color: #555;
      }
      svg {
        max-width: 15px;
      }
    }
  }
  .title-preview {
    font-size: 1.2em;
  }
  .icon-preview {
    font-size: 3.2em;
  }
</style>
