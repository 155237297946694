<!-- Compoenent actions  -->
<template>
  <div class="mb-3">
    <FullCalendar :options="calendarOptions" />
  </div>
</template>

<script>

import moment from 'moment'
import {ACTION_REQUEST_ACTIONS_UPDATE, ACTION_REQUEST_ACTIONS_UPDATE_DATE} from '../../mutations-types'

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from '@fullcalendar/core/locales/fr';

/**
 * @group _ Module Actionsboard
 * This component display all actions list
 */
  export default {
    name: 'TodoActionsCalendar',
    components: {
      FullCalendar,
    },
    props: ['actions', 'priorities', 'pdca_values', 'users'],
    data () {
      return {
        module: 'modules.'+this.$route.meta.moduleName,
        filters: [],
        worldId: this.$store.getters['auth/getWorld'].id,
        selectedTaskIs : {},
      }
    },
    methods: {
      eventClick(info) {
        this.selectTaskDetails(this.actions.find(e => e.id == info.event.extendedProps.id))
      },
      eventResize(info) {
        this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE_DATE, {
          id: this.$route.params.world,
          entity: 'Actions',
          action: {
            id: info.event.extendedProps.id,
            startDate: moment(info.event.start).format(),
            endDate: moment(info.event.end).subtract('day', 1).format(),
          },
        }).then(() => {
          this.$store.commit('actionsboard/refreshTodo', {
            id: info.event.extendedProps.id,
            key: 'startDate',
            value: moment(info.event.start).format(),
          })
          this.$store.commit('actionsboard/refreshTodo', {
            id: info.event.extendedProps.id,
            key: 'endDate',
            value: moment(info.event.end).subtract('day', 1).format(),
          })
        })
        this.unselectedTask()
      },
      eventContent(info) {
        var node = document.createElement('span')
        node.setAttribute('class', 'fc-draggable fc-day-grid-even fc-start fc-end d-block small font-weight-bold cursor-pointer');
        node.setAttribute('style', 'padding: 2px 3px; background: #F5F5F5; border: 1px solid rgba(0,0,0,.05); border-radius: 4px');
        let eventClass = info.event.extendedProps.done ? 'text-striked' : ''
        node.innerHTML = ''
        node.innerHTML+= `<i class="${this.priorities[info.event.extendedProps.priority].icon} mr-1 text-${this.priorities[info.event.extendedProps.priority].class}"></i>`
        node.innerHTML+= `<span class="word-break ${eventClass}">${info.event.title}</span>`
        return {html: node.outerHTML}
      },
      updateTaskStatus (id, status) {
        this.actions.find(b => b.id === Number(id)).status = status;
        this.pdcaTask(this.actions.find(b => b.id === Number(id)), status)
      },
      /**
       * @vuese
       * Select Pdca Action
       * @arg object
       */
      pdcaTask (task, pdca) {
        task.pdca = pdca
        let object = {
          key: 'pdca',
          value: task.pdca,
          id: task.id
        }
        this.UpdateElementTod(object)
      },
      /**
       * @vuese
       * fubction to update action item object
       * @arg object
       */
      UpdateElementTod (object) {
        this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE, {id: this.worldId, entity: 'Actions', action: object}).then(() => {
          this.$store.commit('actionsboard/refreshTodo', object)
        })
      },
      /**
       * @vuese
       * this function to show detail action selected
       * @arg
       */
      selectTaskDetails (task) {
        if (!(typeof this.selectedTaskIs.id !== 'undefined' && this.selectedTaskIs.id == task.id)) {
          this.selectedTaskIs = task
          this.$emit('onShowDetailTodo', {}, false)
          setTimeout(() => {
            this.$emit('onShowDetailTodo', task, true)
          }, 10)
        }
      },
      //set New DAte Update
      /**
       * @vuese
       * this function change dateStart for action
       * @arg
       */
      chanegDate(elem) {
        this.data.filter(item => {
          if (item.id === elem.id) {
            item.startDate = elem.startDate
          }
        })
      },
    },
    computed: {
      calendarOptions() {
        return {
          plugins: [
            dayGridPlugin,
            interactionPlugin,
          ],
          initialView: 'dayGridMonth',
          locale: this.$i18n.locale.split('-')[1],
          locales: [
            frLocale,
          ],
          displayEventTime: false,
          events: this.actionsFormatted,
          eventClick: this.eventClick,
          eventResize: this.eventResize,
          eventDrop: this.eventResize,
          editable: false,
          selectable: false,
          eventContent: this.eventContent,
        }
      },
      actionsFormatted () {
        return this.actions.map(elem => {
          return {
            startEditable: true,
            id: elem.id,allDay: true,
            title: elem.name,
            start: elem.startDate ? moment(elem.startDate).toDate() : null,
            end: elem.endDate ? moment(elem.endDate).add('day', 1).toDate() : null,
            extendedProps: {
              id: elem.id,
              status: elem.pdca || 'plan',
              priority: elem.priority || null,
              done: elem.done,
              owner: this.users.find(user => { return user.id == elem.owner }),
            },
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.loading-action {
  text-align: center;
  i {
      font-size: 28px;
  }
}
.badge-title {
  padding: 0.3125rem 0rem 0.3125rem 0rem;
}
.badge-Responsable, .badge-Date {
  float: right;
}
.badge-title, .badge-Titre, .badge-Priorité {
  float: left;
}
.clear {
  clear: both;
}
.badge{
  margin-bottom: .625rem!important;
}
i.icon-sort {
  font-size: 13px;
  margin-bottom: -3px;
}

@import '~@fullcalendar/common/main.css';
@import '~@fullcalendar/daygrid/main.css';

/deep/ {
  .fc-view-container {
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.01), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.01), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.03), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.01);
    border-radius: .25rem;
    overflow: hidden;
  }
  .fc-view {
    > table {
      background: white;
      margin: -1px 0px -2px 0px;
      position: relative;
      left: -3px;
      width: calc(100% + 5px);
    }
  }
  .fc-h-event {
    border: none;
    background: none;
  }
  .fc-event-main {
    color: inherit;
  }
  .fc-event-container {
    padding: 1px;
    > div {
      background: #F5F5F5 !important;
      border-radius: 2px;
    }
  }
  .fc-toolbar {
    .fc-left {
      font-size: 0.8em;
      text-transform: capitalize;
    }
  }
}

/deep/ .text-striked {
  text-decoration:line-through;
}
</style>
