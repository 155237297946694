<!-- Component for tag ( users dates and tags) -->
<template>
  <div v-click-outside="clickOutsideAc" :style="{marginLeft: '4px', marginTop: '-16px'}" class="dropdown-menu" :data-id="type" id="tag-autocomplete">
    <input type="hidden" name="element-selected" value="">
      <div class="form-group-feedback form-group-feedback-right mx-2 mb-2">
        <input v-model="search"  type="text" class="form-control px-2"  @input="FilterData()">
        <div class="form-control-feedback"><i class="icon-search4 font-size-base text-muted"></i></div>
      </div>

      <div v-if="elements.length === 0" class="px-3 py-1 text-grey">{{ no_result }}</div>
      <div :class="'dropdown-list '+type">
        <a
          v-if="type === 'users'"
          v-for="item, index in elements"
          :ref="'itemUser' + item.index"
          :data-id="item.index"
          @click.prevent="selectItem(item.index, $event)"
          @mouseenter="currentItem = item.index"
          :class="{'dropdown-item': true, 'active': item.index == currentItem, 'users': true}"
        >
          <div style="width: 20px; height: 20px; line-height: 22px; text-align: center; fontSize: 10px" v-if="!item.avatar.thumb" class="rounded-circle mr-2 bg-grey d-inline-block">
            {{ (item.full_name !== '' && item.full_name !== null)? getInitials(item.full_name) : getInitials(item.email) }}
          </div>
          <img v-if="item.avatar.thumb" :src="item.avatar.thumb" class="rounded-circle mr-2">
          <span>{{ (item.full_name !== '' && item.full_name !== null) ? item.full_name : item.email  }}</span>
        </a>

        <a
          v-if="type === 'date'"
          v-for="item, index in dates"
          :ref="'itemDate' + item.index"
          :data-id="item.index"
          @click="selectItem(item, $event)"
          @mouseenter="currentItem = item.index"
          :class="{'dropdown-item': true, 'active': item.index == currentItem, 'date d-block': true}"
        >
          <div class="float-left">{{ item.name }}</div><div class="ml-2 float-right text-grey">{{ item.date }}</div>
        </a>

        <a
          v-if="type === 'tags'"
          v-for="item, index in tags"
          data-tags="tag"
          :ref="'itemTags' + item.index"
          :data-id="item.index"
          @click="selectItem(item, $event)"
          @mouseenter="currentItem = item.index"
          :class="{'dropdown-item': true, 'active': item.index == currentItem, 'tags d-block': true}"
        >
          <div class="float-left">{{ item.name }}</div><div class="ml-2 float-right text-grey">{{ item.date }}</div>
        </a>

      </div>
  </div>
</template>

<script>

import vClickOutside from 'v-click-outside'
const $ = require('jquery')
window.jQuery = $;
window.$ = $;
import _ from 'lodash'
import {ACTION_REQUEST_ACTIONS_UPDATE, ACTION_STORE_TAGS, ACTION_REQUEST_TAGS, ADD_TAG_TO_ACTION} from '../../mutations-types'
import store from '@/store'
import moment from 'moment'
/**
 * @group _ Module Actionsboard
 * This component display all type tag
 */
export default {
  name: "TagData",
  /**
  * @vuese
  * Use pachage to controlle click outside component _TodoDetail
  * Directives
  */
  directives: {
    clickOutside: vClickOutside.directive
  },
  data ()  {
    return {
      no_result: 'no Result',
      elements: [],
      search: null,
      taskSelected: {},
      worldId: store.getters['auth/getWorld'].id,
      data: store.state.actionsboard.listUsers,
      currentItem: 0,
      tagType: null,
      keys: {
        users: '@',
        // date: '^',
        tags: '#'
      }
    }
  },
  mounted() {
    this.elements = this.data.length > 0 ? this.data : []
    _.forEach(this.elements, (element, index) => {
      this.elements[index].index = index
    })

    $('#tag-autocomplete').on("keyup", this.nextItem);
    $('#tag-autocomplete').on("keydown", function(e) {
      if(e.keyCode == 27) {
        $(this).css('display', 'none')
        let elem = $('input[name="element-selected"]').val()
        let res = elem.split("_")
        $(".newTaskTitle_"+res[1]).focus()
      }
    });

  },
  methods: {
    /**
     * @vuese
     * when i click outSide Component TagData hide this component
     *
     */
    clickOutsideAc (event) {
      $('#tag-autocomplete').hide()
      this.elements = this.data.length > 0 ? this.data: []
      this.search = null
      let elem = $('input[name="element-selected"]').val()
      let res = elem.split("_")
      $(".newTaskTitle_"+res[1]).prop('contenteditable', true)
    },
    /**
     * @vuese
     *select item in create and update data (input id == 0 is create , input id != 0 is update Action exist)
     *
     */
    selectItem (index, e) {
      if(e != null) {
        e.preventDefault()
      }
      let elem = $('input[name="element-selected"]').val()
      let res = elem.split("_")
      let value = null
      if(this.tagType === 'users') {
        let responsible = this.data.filter((value, key) => {
          if(index === key) {
            return value
          }
        })
        value = responsible[0]
      // } else if(this.tagType === 'date') {
      //   let valueDates = this.dates.filter((value, key) => {
      //     if(index === key) {
      //       return value
      //     }
      //   })
      //   value = valueDates[0]
      } else if(this.tagType === 'tags') {
        let valueDates = this.tags.filter((value, key) => {
          if(index === key) {
            return value
          }
        })
        value = valueDates[0]
      }
      /**
       * @vuese
       * Call tagEvent to change title action
       *
       */
      this.tagEvent(elem, index, value, res[1])

      if(elem == 'taskSelectedIs_0') {
        if(this.tagType === 'users') {
          this.$emit('changeResponsable', value.id)
        } else if(this.tagType === 'date') {
          $('.datepickerNewTodo').val(value.date)
          this.$emit('datepickerNewTodoEmit', value.date)
        } else if(this.tagType === 'tags') {
          let tagsValues = []
          let app = this
          $('.'+elem).find('span[type="tags"]').each(function () {
            app.tags.map((item, key) => {
              if($(this).attr('index') == item.id) {
                tagsValues.push(item.id)
              }
            })
          })
          let typeTag = $('.tags[data-id="'+index+'"]').data('tags')
          this.$emit('AddTagEmit', {id: tagsValues, type: typeTag, create: true})
        }

         $(".newTaskTitle_"+res[1]).focus()
      } else if(elem != '') {
        let object = {}
        if(this.tagType === 'users') {
          object = {
            value: value.id,
            key: 'owner',
            id:res[1]
          }
        // } else if(this.tagType === 'date') {
        //   object = {
        //     value: value.date,
        //     key: 'startDate',
        //     id:res[1]
        //   }
        } else if(this.tagType === 'tags') {

          let tagsValues = []
          let app = this
          $('.taskSelectedIs_'+res[1]).find('span[type="tags"]').each(function () {
            app.tags.map((item, key) => {
              if($(this).attr('index') == item.id) {
                tagsValues.push(item.id)
              }
            })
          })

          let typeTag = $('.tags[data-id="'+index+'"]').data('tags')
          this.$emit('AddTagEmit', {id: tagsValues, type: 'tag', create: false})
          object = {
            value: tagsValues,
            key: typeTag,
            id:res[1]
          }
        }
        let objectName = {
          value: $('.taskSelectedIs_'+res[1]).html(),
          key: 'name',
          id:res[1]
        }
        this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE, {id: this.worldId, entity: 'Actions', action: object}).then((resp) => {
          this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE, {id: this.worldId, entity: 'Actions', action: objectName}).then((resp) => {
            this.$store.commit('actionsboard/refreshTodo', object)
            $(".newTaskTitle_"+res[1]).focus()
          })
        })
      }
    },
    /**
     * @vuese
     * Filter Search Tag
     */
    FilterData () {
      if(this.tagType == 'users') {
        this.elements = this.data.filter(item => {
            if(item.full_name.toLowerCase().includes(this.search.toLowerCase())){
              return item
            }
        })
      }
    },
    /**
     * @vuese
     * This function change html
     */
    tagEvent (selector, index, newChoice, id) {
      if(selector != "") {
        let el = $('.'+selector)
        if (el.find('[type="'+this.tagType+'"]').length > 0) {
          if(this.tagType === 'tags') {
              el.find('[type="'+this.tagType+'"]').each(function () {
                if($(this).attr('index') == newChoice.id) {
                  $(this).parent( ".parent" ).remove();
                  $.trim(el.html())
                }
              })
          } else {
            el.find('[type="'+this.tagType+'"]').remove();
            el.html(function (i, html) {
              return html.replace(/&nbsp;/g, '');
            });
          }
        }
        let innerHtml = null
        if(this.tagType === 'users') {
          let full_name = newChoice.full_name !== '' ? newChoice.full_name: newChoice.email
          innerHtml = el.html().replace(this.keys[this.tagType] , ' ') + '<span contenteditable="false" style="position: relative; top: -1px;" type="'+this.tagType+'" index="' + newChoice.id + '" '
          innerHtml += ' name="' + full_name + '"'
          innerHtml += 'class="mx-1 badge badge-primary text-white d-inline">' + full_name + '</span>&nbsp;'
        // }else if(this.tagType === 'date') {
        //   innerHtml = el.html().replace(this.keys[this.tagType] , ' ') + '<span contenteditable="false" style="position: relative; top: -1px;" type="'+this.tagType+'" index="' + newChoice.date + '" '
        //   innerHtml += ' name="' + newChoice.name + '"'
        //   innerHtml += 'class="mx-1 badge badge-primary text-white d-inline">' + newChoice.name + '</span>&nbsp;'
        } else if(this.tagType === 'tags') {
          innerHtml = el.html().replace(this.keys[this.tagType] , ' ') + '<span class="parent"><span contenteditable="false" style="position: relative; top: -1px;" type="'+this.tagType+'" index="' + newChoice.id + '" '
          innerHtml += ' name="' + newChoice.name + '"'
          innerHtml += 'class="mx-1 badge badge-primary text-white d-inline">' + newChoice.name + '</span>&nbsp;</span>'
        }
        el.focus().html(innerHtml);
        var range = document.createRange();
        range.selectNodeContents(el[0]);
        range.collapse(false);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        $('#tag-autocomplete').hide()
      }
    },
    /**
     * @vuese
     * This function controlle navigation in tag Up down
     *
     */
    nextItem () {
      event.preventDefault()
      let elem_item = $('.dropdown-list.'+this.type+' .dropdown-item').length -1
      if (event.keyCode == 38 && this.currentItem > 0) {
        this.currentItem--
      } else if (event.keyCode == 40 && this.currentItem < elem_item) {
        this.currentItem++
      } else if (event.keyCode == 13) {
        let ref = null
        let itemSelected = null
        if(this.tagType === 'users') {
          itemSelected = $('.dropdown-list.'+this.tagType+' .dropdown-item.active').data('id')
          ref = 'itemUser'+ itemSelected
        }else if(this.tagType === 'date') {
          itemSelected = $('.dropdown-list.'+this.tagType+' .dropdown-item.active').data('id')
          ref = 'itemDate'+itemSelected
        }else if(this.tagType === 'tags') {
          itemSelected = $('.dropdown-list.'+this.tagType+' .dropdown-item.active').data('id')
          ref = 'itemTags'+ itemSelected
        }
        if(typeof itemSelected != 'undefined') {
          let className = this.$refs[ref][0].className.split(' ')
          this.selectItem($('a.'+className[0]+'.'+className[1]+'.'+this.tagType).data('id'), event)
        }
        if(typeof itemSelected == 'undefined' && this.tagType == 'tags') {
          let elem = $('input[name="element-selected"]').val()
          let res = elem.split("_")
          this.createNewTag(this.search, elem)
          if(res[1] != 0) {
            let object = {
              name: this.search,
              type: 'Tag',
              worldId: this.worldId
            }
            this.$store.dispatch('actionsboard/' + ACTION_STORE_TAGS, {id: this.worldId, entity: 'Tags', type: 'Tag', tag: object}).then(value => {
              $('.taskSelectedIs_'+res[1]).find('[type="tags"]').each(function () {
                if($(this).attr('name') == object.name && $(this).attr('index') == 0 ) {
                  $(this).attr('index', value[1][0].id)
                }
              })
              store.dispatch('actionsboard/' + ACTION_REQUEST_TAGS, {id: this.worldId, entity: 'Tags', type: 'all'}).then(tags => {
                this.$emit('refreshDataTags')
              })
              this.updateAction(ADD_TAG_TO_ACTION, {id: res[1], key: 'tag', value: value[1][0].id})
              let title = $('.taskSelectedIs_'+res[1]).html()
              this.updateAction(ACTION_REQUEST_ACTIONS_UPDATE, {id: res[1], key: 'name', value: title }, elem)
            })
          }
          this.search = null
        }
        this.currentItem = 0
      }
    },
    /**
     * @vuese
     *  this function add tag (tag ) in title action
     * @arg
     */
    createNewTag (data, selector) {
      let el = $('.'+selector)
      let innerHtml = el.html().replace(this.keys[this.tagType] , ' ') + '<span class="parent"><span contenteditable="false" style="position: relative; top: -1px;" type="'+this.tagType+'" index="0" '
      innerHtml += ' name="' + data + '"'
      innerHtml += 'class="mx-1 badge badge-primary text-white d-inline">' + data + '</span>&nbsp;</span>'
      el.focus().html(innerHtml);
      var range = document.createRange();
      range.selectNodeContents(el[0]);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      $('#tag-autocomplete').hide()
    },
    /**
     * @vuese
     *  this function update actions and refresh store
     * @arg
     */
    updateAction (mutation, object, elem = null) {
      this.$store.dispatch('actionsboard/' + mutation, {
        id: this.worldId,
        entity: 'Actions',
        action: object
      }).then((resp) => {
        Promise.all([
          this.$store.commit('actionsboard/refreshTodo', object)
        ]).finally(() => {
          if(mutation == ACTION_REQUEST_ACTIONS_UPDATE) {
            var range = document.createRange();
            range.selectNodeContents($('.'+elem)[0]);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
          }
        })
      })
    }
  },
  computed : {
    /**
     * @vuese
     *  Get datee to show in tags list
     *
     */
    dates () {
      let dates = []
      for (var i = 0; i <= 7; i++) {
        let timestamp = Math.round(new Date().getTime()) + (24 * 60 * 60 * 1000 * i)
        timestamp = moment(timestamp).format('D MMM YYYY')
        let name = ''
        if (i === 0) {
          name = this.$t('date.today')
        } else if (i === 1) {
          name = this.$t('date.tomorrow')
        } else if (i === 7) {
          name = this.$t('date.next_week')
        } else {
          name = this.$t('date.in', { nb: i })
        }
        dates[i] = {
          index: i,
          name: name,
          date: timestamp
        }
      }
      if(this.search != null) {
        dates = dates.filter(item => {
          if(item.name.toLowerCase().includes(this.search.toLowerCase())){
            return item
          }
        })
      }

      return dates
    },
    /**
     * @vuese
     *change type data show in this COmponent (users, date, tags)
     * @type String
     */
    type () {
      this.tagType = store.state.actionsboard.typeTag
      return store.state.actionsboard.typeTag
    },

    /**
     * @vuese
     *change type data show in this COmponent (users, date, tags)
     * @type String
     */
    tags () {
      let tags
      if(this.search != null) {
        tags = store.state.actionsboard.tagsTag.filter(item => {
          if(item.name.toLowerCase().includes(this.search.toLowerCase())){
            return item
          }
        })
      } else {
        tags = store.state.actionsboard.tagsTag
      }

      _.forEach(tags, (element, index) => {
        tags[index].index = index
      })

      return tags
    }
  },
}
</script>

<style lang="scss" scoped>
.input-group .form-group-feedback {
  flex: initial;
  width: 90%;
  z-index: auto;
}
.dropdown-item:not(.active):hover {
    background: inherit;
    color: inherit !important;
}
</style>
